.Leadership {
  position: relative;
}

.Leadership .pl-14p {
  padding-left: 16px;
  padding-bottom: 16px;
}

.scroll-top {
  position: fixed !important;
  right: 55px;
  bottom: 40px;
}

#grid_leadership {
  padding: 0px !important;
}

.user-profile {
  padding-top: 11px;
}

.user-profile .rs-panel-body {
  padding-top: 16px;
}

.user-profile .bold-text {
  font-size: 18px !important;
  font-weight: bold;
  font-family: "apercu-bold";
}

.user-profile .email-txt {
  padding-left: 5px;
}

.user-profile .input-info-style {
  font-family: "apercu-bold";
  font-size: 16px;
}
.user-profile .footer-actions-style {
  padding-top: 32px;

  font-family: "apercu-mono-regular";
}

.user-profile .rs-modal-header .rs-modal-title {
  line-height: 1.5;
}

.user-profile .buttons-font {
  background-color: #f5f5f5;
  color: #323232;
  font-family: "apercu-mono-regular";
}

.user-profile .txt-style {
  font-family: "apercu-mono-regular";
}

.user-profile .title-dialog-style {
  font-family: "apercu-bold";
}

.user-profile .mb-2 {
  margin-bottom: 2px;
}

.user-profile .rs-modal-body {
  padding-bottom: 15px;
  margin-top: 15px;
}

.user-profile .txt-err {
  min-height: 20px;
}

.user-profile .mrimg-4 {
  margin-right: 4px;
}

.user-profile .p-relative {
  position: relative;
}

.user-profile .delete-avatar {
  position: absolute;
  left: 90px;
  top: 4px;
  z-index: 100;
}

.user-profile .user-details input:focus {
  background-color: #eee;
}

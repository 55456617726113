.Top3LegPending {
  color: #505050;
  font-family: "apercu-bold";
  padding: 16px;
  font-size: 16px;
}

.Top3LegPending .title {
  color: #323232;
  font-family: "apercu-bold";
  font-size: 16px;
}

.Top3LegPending .rs-panel-heading,
.Top3LegPending .rs-panel-body {
  padding: 0px;
}

.Top3LegPending .content {
  font-family: "apercu-bold";
  color: #323232;
  font-size: 16px;
  line-height: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 13px;
}

.Top3LegPending .rs-grid-container-fluid {
  padding-left: 0px;
}

.Top3LegPending .footer {
  padding-top: 5px;
  font-size: 12px;
  font-family: "apercu-bold";
}

.Top3LegPending .rs-btn.rs-btn-icon {
  padding: 5px;
}

.Top3LegPending .left-border {
  padding-top: 5px;
  height: 40px;
  border-left: dashed 1px #00000029;
}

.Top3LegPending .Img-Style {
  padding-top: 2px;
}

.Top3LegPending .circle-wrapper {
  width: 44px;
}

.Top3LegPending .rs-row {
  margin-top: 8px;
}

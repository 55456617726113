.ActorsCard {
  padding: 0px;
  color: #323232;
  font-family: "apercu-bold";
  min-width: 357px;
  font-size: 13px;
}

.ActorsCard .admin-level {
  float: right;
  position: relative;
  right: 3px;
  font-size: 13px;
  color: #bfbfbf;
  font-family: "apercu-regular";
  padding-top: 6px;
}

.ActorsCard .separator {
  font-family: "apercu-regular";
  color: #bfbfbf;
  margin-left: 8px;
  margin-right: 8px;
}

.ActorsCard .overflow-txt {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ActorsCard .count-style {
  font-family: "apercu-regular";
  margin-left: 8px;
}

.ActorsCard .rs-panel-body {
  padding: 8px 8px 4px 8px !important;
}

.ActorsCard .panel-header {
  font-size: 13px;
}

.ActorsCard .profile-title {
  font-size: 16px;
  margin-top: 5px;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  width: 55%;

  color: #323232;
}

.ActorsCard .rs-avatar-xs {
  height: 24px;
  width: 24px;
  padding: 4px;
}

.ActorsCard .score {
  float: left;
  background: #f5f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 8px 12px;
  position: relative;
  height: 32px;
  font-family: "apercu-mono-regular";
}

.ActorsCard .profile-type-regular {
  font-family: "apercu-regular";
}
.ActorsCard .profile-type {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #bfbfbf;
  line-height: 14px;
  font-family: "apercu-bold";
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  float: left;
  overflow: hidden;
}

.ActorsCard .profile-pnl {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 14px;
}

.ActorsCard .pt4 {
  padding-top: 4px;
}

.ActorsCard .profile-pic {
  width: 80px;
  height: 80px;
  border: 5px solid #b19fbf;
  margin-top: 5px;
  margin-bottom: 10px;
}

.ActorsCard .federal {
  border-color: #bfae9f;
}

.ActorsCard .profile-info-l {
  margin-left: 10px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
}

.ActorsCard .pb8 {
  padding-bottom: 8px;
}

.ActorsCard .icon-mr {
  margin-right: 12px;
}

.ActorsCard .follow-link {
  color: #b3b3b3;
  font-family: "apercu-mono-regular";
}

.object-arrow-up {
  margin-left: 5px;
  color: #53818a;
}

.object-arrow-down {
  margin-left: 5px;
  color: #de9a75;
}

.ActorsCard .icon-positive {
  background-color: #53818a;
}

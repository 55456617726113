.dashboard {
  background-color: rgb(245, 245, 245);
}
.dashboard .menu-item-class {
  font-size: 18px;
  font-family: "apercu-bold";
  color: #747474;
}

.dashboard .title-style {
  padding: 24px;
  font-size: 1.5em;
  height: 115px;
  color: #a55452;
  width: 50px;
  line-height: 1em;
  font-family: "apercu-bold";
}
.dashboard .pl-15 {
  padding-left: 15px;
}

.dashboard .padding-right {
  padding-right: 65px;
}

.dashboard .pr-10 {
  padding-right: 10px;
}

.dashboard .m-5 {
  margin: 5px;
}

.dashboard .lang-color-font {
  color: #323232;
}

.dashboard .color-txt {
  color: #848484;
}

.dashboard .footer-side-menu-style {
  position: absolute;
  bottom: 10px;
  padding: 14px;
  background-color: white;
  width: 220px;
  left: 0px;
  font-size: 13px;
  color: #323232;
}

.dashboard .rs-btn-lg {
  font-size: 13px;
}

.dashboard .btn-grp {
  border: 2px solid #f5f5f5;
  border-radius: 6px;
}

.dashboard .hrefStyle {
  text-decoration: none;
  color: #323232;
  font-size: 13px;
  font-family: apercu-bold;
}

.dashboard .hrefStyle:hover {
  text-decoration: none;

  color: #323232;
  font-size: 13px;
}

.dashboard .hrefStyle:visited {
  text-decoration: none;
  color: #323232;
  font-size: 13px;
}

.dashboard .nav-footer-login {
  margin: 0 auto;
  width: 700px;
  height: 100px;
  padding-top: 20px;
}

.dashboard .avatar-link {
  background-color: #2f469c;
}

.dashboard .new-feed .rs-badge-content {
  top: 10px;
}

.dashboard .pt-12 {
  padding-top: 12px;
}

.dashboard .hamburger {
  display: none;
  position: fixed;
  left: 40px;
  bottom: 40px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #8a1700;
  color: white;
  padding: 5px;
}

#main-container {
  max-width: 1680px;
  margin: auto;
  padding: 24px;
}

.wrapper {
  padding-left: 265px;
}

.side-nav {
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  margin-right: 24px;
  height: calc(100% - 45px);
  z-index: 5;
}

.rs-nav-item-content:focus {
  background: none !important;
}

.rs-nav-item-content:hover {
  background: #e5e5ea;
}

.rs-footer {
  left: 0px;
}

.logo-link:hover {
  text-decoration: none;
  color: #a55452;
}

.logo-link {
  color: #a55452;
}

.drop-overlay {
  width: 100%;
  height: 5000px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
}

.nav-close {
  position: absolute;
  left: 240px;
  bottom: 20px;
  background: #f5f5f5;
  padding: 10px;
  width: 40px;
  padding-left: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.FeedPost {
  padding: 16px 16px 12px 16px;
  font-size: 13px;
}

.FeedPost .bold-title {
  font-family: apercu-bold;
}

.FeedPost .profile-pic {
  width: 80px;
  height: 80px;
  border: 5px solid #b19fbf;
  margin-top: 5px;
  margin-bottom: 10px;
}

.FeedPost .title {
  font-size: 16px;
  color: #323232;
  padding-bottom: 4px;
}

.FeedPost .rs-panel-heading,
.FeedPost .rs-panel-body {
  padding: 0px;
}

.FeedPost img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.FeedPost .ml-10px {
  margin-left: 10px;
}

.FeedPost .mr-25px {
  margin-right: 25px;
}

.tag-style a,
.tag-style a:hover {
  font-family: "apercu-bold";
}

.FeedPost .tag-style {
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
}

.FeedPost .unfollow {
  float: left;
  font-size: 13px;
  color: #bfbfbf;
}

.FeedPost .unfollow button {
  text-decoration: none;
  color: #bfbfbf;
}

.FeedPost .unfollow a {
  text-decoration: none;
  color: #bfbfbf;
}

.FeedPost a:hover {
  text-decoration: none;
}

.FeedPost .sentiment {
  padding: 8px 12px;
  background-color: #eee;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  float: right;
  font-family: "apercu-mono-regular";
  font-size: 13px;
  height: 32px;
  margin-left: 10px;
}

.FeedPost .circle {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #53818a;
  display: block;
}

.FeedPost .news-content {
  font-size: 16px;
  color: #505050;
}

.FeedPost .timeStamp,
.FeedPost .retweet,
.FeedPost .subtitle,
.FeedPost .tag-style,
.FeedPost .unfollow {
  font-size: 13px;
  font-family: "apercu-mono-regular";
}

.FeedPost .mtb-8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.FeedPost .mb16px {
  margin-bottom: 16px;
  margin-top: 0px;
}

.FeedPost .federal {
  border-color: #bfae9f;
}

.FeedPost .positive {
  background-color: #de9a75 !important;
  color: #de9a75 !important;
}

.FeedPost .negative {
  background-color: #de9a75 !important;
  color: #de9a75 !important;
}

.neutral {
  background-color: #bfbfbf !important;
}

.big-gray {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background: lightgrey;
  margin-top: 10px;
}

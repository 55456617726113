.AddUser {
  padding: 16px;
  margin-top: 16px;
}

.AddUser .message {
  color: gray;
  margin-bottom: 10px;
}

.AddUser .no-margin {
  margin: 0px;
}

.AddUser .pr-20 {
  padding-right: 20px;
}

.AddUser .rs-panel-heading,
.AddUser .rs-panel-body {
  padding: 0px;
}

.NationalOverview .container {
  background-color: white;
  padding: 3px 15px 8px 15px;
  padding-bottom: 0px;
  border-color: #ccc;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
}

.firstPie > div > div > div > svg > text.highcharts-title > tspan {
  color: red;
}

.NationalOverview .filters-style {
  position: relative;
  bottom: -6px;
  margin-top: 8px;
  right: -8px;
}

.NationalOverview .mb14 {
  margin-bottom: 14px;
}

.NationalOverview .mt-12px {
  margin-top: 12px;
}

.NationalOverview .dividerHeight {
  height: 0.5px;
}

.NationalOverview .dividerHeight {
  height: 0.5px;
}

.NationalOverview .mb15 {
  margin-bottom: 15px;
}

.NationalOverview .rs-table-cell-header-sortable .rs-table-cell-content {
  font-family: "apercu-mono-regular";
  font-size: 13px;
  color: #747474;
}

.NationalOverview .bordered {
  border: 2px solid #f5f5f5;
  border-radius: 6px;
  margin-bottom: 20px;
}

.NationalOverview .main-row {
  font-family: "apercu-mono-bold";
  font-size: 16px;
  color: #323232;
}

.NationalOverview .rest-row {
  font-family: "apercu-mono-regular";
  font-size: 16px;
  color: #323232;
}

.NationalOverview .caption {
  color: #323232;
  font-family: "apercu-mono-bold";
}

.NationalOverview .filter-font {
  font-family: "apercu-mono-regular";
}

.NationalOverview .mr-8px {
  margin-right: 8px;
}

.NationalOverview .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #323232;
}

.NationalOverview .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 3px;
}

.NationalOverview .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 18px;
}

.NationalOverview .highcharts-legend-item {
  font-family: "apercu-mono-regular";
}
.NationalOverview .highcharts-title {
  color: #323232;
  font-family: "apercu-bold";
}

.NationalOverview .filter-position {
  position: relative;
  top: -24px;
  color: #323232;
  background-color: #f5f5f5;
}

.bottom-zero {
  bottom: 0 !important;
}

.LegislationCard {
  color: #323232;
  font-family: "apercu-bold";
  font-size: 13px;
  padding: 8px 8px 0px 8px !important;
}

.LegislationCard .rs-panel-body {
  padding: 0px;
}

.LegislationCard .arrow-style {
  padding: 2px;
  padding-left: 10px;
}

.LegislationCard .leg-card-header {
  color: #bfbfbf;
  font-family: "apercu-bold";
  line-height: 14px;
  min-height: 45px;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: ellipsis;
}

.LegislationCard .icon-mr {
  margin-right: 12px;
}

.LegislationCard .icon-ml {
  margin-left: 12px;
}

.LegislationCard .panel-header {
  font-size: 12px;
}
.LegislationCard .progress-wraper {
  width: 80px;
  height: 80px;
  border: 5px solid #b19fbf;
  margin-top: 5px;
  margin-bottom: 10px;
}

.LegislationCard .circle-wrapper {
  width: 80px;
  height: 80px;
  margin-top: 11px;
  margin-bottom: 10px;
}

.LegislationCard .arrow-wrapper {
  padding-top: 5px;
  padding-right: 6px;
}

.LegislationCard a {
  text-decoration: none;
  color: #000;
}

.LegislationCard .profile-title-style {
  font-size: 16px;
  margin-left: 12px;
  font-family: "apercu-bold";
  letter-spacing: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #323232;
  padding-top: 5px;
}

.LegislationCard div.inline {
  float: left;
}

.LegislationCard .circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #53818a;
  margin-bottom: 5px;
  display: block;
  margin-top: 2px;
}

.LegislationCard .positive {
  background-color: #53818a !important;
  color: #de9a75 !important;
}

.LegislationCard .negative {
  background-color: #de9a75 !important;
  color: #de9a75 !important;
}

.LegislationCard .neutral {
  background-color: #bfbfbf !important;
}

.LegislationCard .first-header-part {
  float: left;
}
.LegislationCard .second-header-part {
  float: right;
  padding-right: 35px;
}

.LegislationCard .clearBoth {
  clear: both;
}

.LegislationCard .score {
  float: left;
  background: #eee;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-family: "apercu-mono-regular";
  color: #323232;
  padding: 8px 12px;
  height: 32px;
}

.LegislationCard .profile-type {
  margin-top: 1px;
  margin-bottom: 3px;
  color: #b3b3b3;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
}

.LegislationCard .profile-pic {
  width: 80px;
  height: 80px;
  border: 5px solid #b19fbf;
  margin-top: 12px;
  margin-left: 0px;
}

.LegislationCard .mb-8px {
  margin-bottom: 8px;
}

.LegislationCard .profile-info-l {
  margin-left: 5px;
  padding-top: 8px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
}

.LegislationCard .follow-link {
  color: #bfbfbf;
  font-family: "apercu-mono-regular";
  padding-top: 4px;
}

.LegislationCard .goto a,
.LegislationCard .goto a:hover {
  color: #bfbfbf;
  font-family: "apercu-mono-regular";
  position: relative;
  top: -3px;
}

.LegislationCard .number-font-size {
  font-size: 12px;
  vertical-align: bottom;
}

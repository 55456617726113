.Top3Actors {
  padding: 16px;
  font-size: 16px;
}

.Top3Actors .rs-panel-heading,
.Top3Actors .rs-panel-body {
  padding: 0px;
}

.Top3Actors .title {
  color: #323232;
  font-size: 16px;
  font-family: "apercu-bold";
}

.Top3Actors .rs-row {
  margin-top: 8px;
}

.Top3Actors .panel-header {
  padding-bottom: 5px;
}
.Top3Actors .jobtitle {
  font-size: 13px;
  color: #bfbfbf;
  font-family: "apercu-regular";
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 280px;
  overflow: hidden;
  padding-top: 4px;
}

.Top3Actors .profile-pic {
  width: 44px;
  height: 44px;
  border: 4px solid #b19fbf;
  margin-top: 5px;
  margin-bottom: 10px;
}

.profile-pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Top3Actors .federal {
  border-color: #bfae9f;
}

.Top3Actors .content {
  font-family: "apercu-bold";
  color: #323232;
  font-size: 16px;
  padding-top: 8px;
  line-height: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 13px;
}

.Top3Actors .footer {
  padding-top: 5px;
  font-size: 12px;
  font-family: "apercu-bold";
}

.Top3Actors .rs-btn.rs-btn-icon {
  padding: 5px;
}

.Top3Actors .left-border {
  padding-top: 10px;
  height: 45px;
  border-left: dashed 1px #00000029;
  position: absolute;
  top: -3px;
  padding-left: 12px;
  right: 5px;
}

.Top3Actors .Img-Style {
  padding-top: 2px;
}

.Top3Actors .circle-wrapper {
  width: 40px;
  padding-left: 3px;
  padding-bottom: 7px;
  padding-top: 6px;
  margin-right: -10px;
  padding-right: 10px;
}

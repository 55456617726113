.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.Signup {
  padding: 60px 15px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.Signup .form-control {
  font-size: 16px;
}

.Signup .login-page-style {
  text-decoration: none;
  color: #ccc;
}

.Signup .btn-font-txt {
  font-family: "apercu-mono-regular";
}

.Signup .input-txt-style {
  font-family: "apercu-mono-regular";
}

.Signup .login-page-style:visited {
  text-decoration: none;
  color: #ccc;
}

.Signup .login-page-style:hover {
  text-decoration: none;
  color: #ccc;
}

.Signup .div-lnk-login-page {
  margin: 0px auto;
  width: 65%;
  padding-top: 10px;
  color: #323232;
  font-size: 16px;
}

.Signup .signupbtn {
  border: none;
  background-color: #f5f5f5;
}

.Signup .form-group {
  margin: 0px;
}

.Signup .txt-err {
  min-height: 20px;
}

.Signup .form {
  margin: 0 auto;
  width: 700px;
  top: 125px;
  position: relative;
}

.Signup form {
  width: 400px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: absolute;
  right: 15px;
}

.Signup .title {
  float: left;
  padding: 48px 30px 30px 30px;
  font-size: 32px;
  height: 100px;
  color: #b14e4e;
  width: 50px;
  font-weight: 900;
  line-height: 1em;
  font-family: "apercu-bold";
}

.Signup .signupbtn {
  background-color: #f5f5f5;
  border: #f5f5f5;
  font-size: 16px;
}

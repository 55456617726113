.Introduce {
  padding: 16px;
}

.Introduce .title {
  color: #323232;
  font-family: "apercu-bold";
  font-size: 16px;
}

.Introduce .actor-title {
  font-size: 16px;
  color: #505050;
}

.Introduce .rs-panel-heading,
.Introduce .rs-panel-body {
  padding: 0px;
}

.Introduce .content {
  font-family: "apercu-regular";
  color: #a8a8a8;
  font-size: 13px;
}

.actor-title a,
.actor-title a:hover {
  font-size: 16px;
  color: #505050;
}

.Introduce .rs-content {
  padding-top: 10px;
}

.Introduce .profile-pic {
  width: 80px;
  height: 80px;
  border: 8px solid #b19fbf;
  margin-top: 12px;
}

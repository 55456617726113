.new-post {
  background-color: #f0f0f0;
  border-radius: 5px;
  position: fixed;
  left: 50%;
  top: 90px;
  padding: 5px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}

.divider {
  margin: 0px;
}

.mt-5px,
.mt5px {
  margin-top: 5px;
}
.mt15px {
  margin-top: 15px;
}

.mt-20px {
  margin-top: 20px;
}

.pt-15px {
  padding-top: 15px;
}

/*::-webkit-scrollbar {
  display: none;
}*/

.ObjectDetailsFilters .index {
  padding: 8px 12px;
  float: left;
  background-color: #f5f5f5;
  font-family: "apercu-mono-regular";
  font-size: 13px;
  height: 32px;
}

.ObjectDetailsFilters .bck-issues {
  background-color: #f5f5f5;
  font-family: "apercu-mono-regular";
  font-size: 13px;
}

.ObjectDetailsFilters .icons {
  padding: 6px 12px;
  font-family: "apercu-mono-regular";
  font-size: 13px;
  height: 32px;
}

.ObjectDetailsFilters .mr24 {
  margin-right: 24px;
}

.ObjectDetailsFilters .rightIcons {
  padding: 8px 12px;
  font-family: "apercu-mono-regular";
  font-size: 13px;
  height: 32px;
}

.ObjectDetailsFilters .border-style {
  border-top: solid 2px #f5f5f5;
  border-left: solid 2px #f5f5f5;
  border-right: solid 2px #f5f5f5;
  color: #323232;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ObjectDetailsFilters .mr-8px {
  margin-right: 8px;
}

.ObjectDetailsFilters .ml8 {
  margin-left: 8px;
}

.ObjectDetailsFilters .mr8 {
  margin-right: 8px;
}

.ObjectDetailsFilters {
  margin-top: 3px;
}

.ObjectDetailsFilters .rs-divider {
  margin: 0;
}

.ObjectDetailsFilters .rs-picker-toggle {
  padding-top: 7px;
  padding-bottom: 3px;
}

.ObjectDetailsFilters .title {
  font-size: 18px;
  font-family: "apercu-bold" !important;
}

.ObjectDetailsFilters .mr8px {
  margin-right: 8px;
}

.ObjectDetailsFilters .circle {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-bottom: 5px;
  display: block;
  margin-top: 3px;
}

.ObjectDetailsFilters .federal {
  border-color: #bfae9f;
}

.ObjectDetailsFilters .positive {
  background-color: #53818a;
  color: #de9a75 !important;
}

.ObjectDetailsFilters .negative {
  background-color: #de9a75;
  color: #de9a75 !important;
}

.ObjectDetailsFilters .neutral {
  background-color: #bfbfbf;
}

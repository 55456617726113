.LeadershipDetails {
  width: 100%;
}

.LeadershipDetails .filters-style {
  position: relative;
  bottom: 0px;
  margin-top: 17px;
}

.LeadershipDetails .divider {
  margin: 0px;
  margin-bottom: 7px;
}

.LeadershipDetails .mt5px {
  margin-top: 5px;
}

.leadership-profile .info {
  font-family: "apercu-bold";
  margin-bottom: 12px;
  color: #323232;
}

.leadership-profile .rs-panel-heading,
.leadership-profile .rs-panel-body {
  padding: 0px;
}

.leadership-profile .rs-panel-body {
  padding: 16px 16px 0px 16px;
  font-size: 16px;
}

.leadership-profile .profile-pic {
  width: 112px;
  height: 112px;
  border: 5px solid #b19fbf;
  margin-top: 5px;
  margin-bottom: 10px;
}

.result-padding {
  padding-left: 2px;
}

.leadership-profile .ex-padding {
  padding-left: 3px;
}

.leadership-profile .federal {
  border-color: #bfae9f;
}

.leadership-profile .profile-info {
  margin-left: 10px;
  padding-top: 10px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
}

.leadership-profile .img-style {
  padding-right: 10px;
}

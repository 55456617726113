.forgetpswd form span.help-block {
  padding-bottom: 10px;
  color: #bfbfbf;
}

.forgetpswd {
  padding: 60px 15px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.forgetpswd .resetbtn,
.forgetpswd .submitbtn {
  background-color: #f5f5f5;
  border: #f5f5f5;
  font-size: 16px;
}

.forgetpswd #resetForm {
  font-size: 16px;
}

.lnk-login-pg {
  text-decoration: none;
  color: #bfbfbf;
}

.lnk-login-pg:visited {
  text-decoration: none;
  color: #bfbfbf;
}

.lnk-login-pg:hover {
  text-decoration: none;
  color: #bfbfbf;
}

.div-login-pg {
  padding-top: 10px;
  color: #bfbfbf;
}

.form-group {
  margin: 0px;
}

.txt-err {
  min-height: 20px;
}

.help-block {
  margin-top: 0px;
  margin-bottom: 5px;
}
.forgetpswd .form {
  margin: 0 auto;
  width: 700px;
  top: 125px;
  position: relative;
}

.btn-font-color {
  color: #323232;
  background-color: rgb(238, 238, 238);
}

.forgetpswd form {
  width: 400px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: absolute;
  right: 15px;
}

.input-txt-style {
  font-family: "apercu-mono-regular";
}

.forgetpswd .title {
  float: left;
  padding: 48px 30px 30px 30px;
  font-size: 32px;
  height: 100px;
  color: #b14e4e;
  width: 50px;
  font-weight: bold;
  font-weight: 900;
  line-height: 1em;
  font-family: "apercu-bold";
}

.txt-err {
  min-height: 20px;
}
.pb-0 {
  padding-bottom: 0px;
}

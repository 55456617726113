.new-post {
  background-color: #f0f0f0;
  border-radius: 5px;
  position: fixed;
  left: 50%;
  top: 90px;
  padding: 5px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}

.Home .scrollable {
  overflow-y: scroll;
}

.Home .divider {
  margin: 0px;
  margin-bottom: 5px;
}

.Home .mt-19px {
  margin-top: 19px;
}

.Home .filters-style {
  position: relative;
  bottom: 0px;
  right: 0px;
  margin-top: 10px;
}

.Home .pr15px {
  padding-right: 5px;
}

/*::-webkit-scrollbar {
  display: none;
}*/

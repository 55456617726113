.LegislationProfile {
  color: black;
}

.LegislationProfile .content-right {
  text-align: right;
}

.LegislationProfile .tags {
  float: left;
  margin-right: 8px;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: "apercu-mono-regular";
}

.LegislationProfile .mr-40p {
  margin-right: 40px;
}

.LegislationProfile .icon-line-space {
  margin-right: 12px;
  font-family: "apercu-mono-regular";
}

.LegislationProfile .icon-icon-space {
  margin-right: 16px;
  font-family: "apercu-mono-regular";
}

.LegislationProfile .row-btw-icon {
  height: 12px;
}

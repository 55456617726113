.CompaniesCited {
  padding: 16px;
}

.CompaniesCited .title {
  color: #323232;
  font-size: 16px;
  font-family: "apercu-bold";
}

.CompaniesCited .rs-panel-heading,
.CompaniesCited .rs-panel-body {
  padding: 0px;
}

.CompaniesCited .badgePadding {
  padding-right: 14px;
}

.CompaniesCited .avatar-border {
  border: solid 1px #bfbfbf;
  padding: 7px;
  border-radius: 5px;
  float: left;
  width: auto;
  display: block;
  height: 70px;
  background: white;
  font-size: 13px;
}

.CompaniesCited .rs-badge-wrapper .rs-badge-content {
  background: white;
  color: #323232;
  border: 1px #323232 solid;
  top: 4px;
  right: 4px;
}

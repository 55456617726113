.UserCard {
  padding: 8px 12px 0px 8px;
}
.inner-panel {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 12px #00000029;
  border-radius: 10px;
}

.input-no-border-rad {
  border-radius: 2px;
}

.UserCard .rs-panel-heading,
.UserCard .rs-panel-body {
  padding: 0px;
}

.UserCard .panel-header {
  font-size: 12px;
}

.UserCard .m-0 {
  margin: 0px;
}

.UserCard .p-0 {
  padding: 0px;
}

.UserCard .profile-title {
  font-size: 13px;
  font-weight: bold;
}

.UserCard .p-5 {
  padding: 5px;
  padding-bottom: 0px;
}

.UserCard .mr-5 {
  margin-right: 5px;
}

.UserCard .pb-5 {
  padding-bottom: 5px;
}

.UserCard .mb-5 {
  margin-bottom: 5px;
}

.UserCard .profile-type {
  margin-top: 5px;
  margin-bottom: 3px;
  color: #b3b3b3;
}

.UserCard .profile-pic {
  width: 80px;
  height: 80px;
  margin-bottom: 12px;
  background: #2f469c;
  font-size: 30px;
}

.UserCard .divider {
  color: #bfbfbf;
  height: 1px;
  margin-top: 0px;
  margin-bottom: 4px;
}

.UserCard .info {
  color: #bfbfbf;
  font-size: 13px;
  font-family: "apercu-regular";
}

.info a,
.info a:hover {
  color: #bfbfbf;
  font-size: 13px;
  font-family: "apercu-regular";
}

.UserCard .fullname {
  font-size: 16px;
  color: #323232;
  font-family: "apercu-bold";
}

.UserCard .profile-info {
  margin-left: 10px;
  margin-top: -2px;
  width: 70%;
  overflow: hidden;
}

.UserCard .follow-link {
  color: #b3b3b3;
  float: left;
  margin-top: 1px;
}

.UserCard .bold {
  font-weight: bold;
  font-size: 15px;
}

.UserCard .admin-b {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 2px;
}

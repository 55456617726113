.Status {
  padding: 0px;
  color: #505050;
  font-family: "apercu-bold";
}

.Status .title {
  color: #323232;
  font-size: 16px;
}

.Status .content-style {
  color: #323232;
  font-size: 13px;
  font-family: "apercu-regular";
}

.Status .rs-progress-circle {
  width: 22px;
  height: 22px;
}

.Status .steps-date {
  color: #b2b2b2;
  font-family: "apercu-regular";
}

.Status .rs-panel-heading,
.Status .rs-panel-body {
  padding: 16px;
}

.Status .rs-row {
  margin-left: -12px;
}

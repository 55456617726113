.Profile .panel {
  background: white;
}

.Profile .divider {
  margin-top: 36px;
}

.Profile .tab-selected {
  background-color: #e8e8e8;
}

.Profile .big-avatar {
  width: 100px;
  height: 100px;
  background-color: #2f469c;
  font-size: 35px;
  font-family: "apercu-bold";
}

.rs-modal-body {
  padding-bottom: 15px;
  margin-top: 15px;
}

.Profile .container {
  padding-top: 18px;
}

.Profile .user-details {
  margin-left: 20px;
}

.Profile .job-title {
  color: #bcbcbc;
  font-size: 13px;
}

.divider-reg {
  margin-bottom: 16px;
}

.Profile .user-email {
  color: #bcbcbc;
  font-size: 16px;
}

.Profile .location {
  color: slategray;
  margin-top: 0px;
}

.Profile .settings {
  margin-top: 20px;
}

.Profile .s-divider {
  margin-top: 12px;
}

.Profile .ml-10 {
  margin-left: 10px;
}

.Profile .p-10 {
  padding: 10px 20px 10px 0;
}

.Profile .legislation {
  margin-top: 20px;
}

.Profile .load-more {
  margin: auto;
  width: 100px;
  margin-top: 20px;
  display: block;
}

.Profile .text-input {
  float: left;
  width: 200px;
  border: 0;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  padding-left: 4px;
}
.Profile .text-icon {
  width: 15px;
  padding-top: 3px;
}

.Profile .toolbar-style {
  font-family: "apercu-mono-regular";
  color: #323232;
  font-size: 13px;
  position: relative;
  bottom: 2px;
}

.Profile #btn_settings,
.Profile #btn_subscriptions {
  margin-right: 8px;
}

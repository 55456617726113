@font-face {
  font-family: "apercu-regular";
  src: url("assets/fonts/apercu-regular.eot");
  src: url("assets/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/apercu-regular.woff2") format("woff2"),
    url("assets/fonts/apercu-regular.woff") format("woff"),
    url("assets/fonts/apercu-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-mono-regular";
  src: url("assets/fonts/apercu-mono-regular.eot");
  src: url("assets/fonts/apercu-mono-regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/apercu-mono-regular.woff2") format("woff2"),
    url("assets/fonts/apercu-mono-regular.woff") format("woff"),
    url("assets/fonts/apercu-mono-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-mono-bold";
  src: url("assets/fonts/apercu-mono-bold.eot");
  src: url("assets/fonts/apercu-mono-bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/apercu-mono-bold.woff2") format("woff2"),
    url("assets/fonts/apercu-mono-bold.woff") format("woff"),
    url("assets/fonts/apercu-mono-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-italic";
  src: url("assets/fonts/apercu-italic.eot");
  src: url("assets/fonts/apercu-italic.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/apercu-italic.woff2") format("woff2"),
    url("assets/fonts/apercu-italic.woff") format("woff"),
    url("assets/fonts/apercu-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-bold";
  src: url("assets/fonts/apercu-bold.eot");
  src: url("assets/fonts/apercu-bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/apercu-bold.woff2") format("woff2"),
    url("assets/fonts/apercu-bold.woff") format("woff"),
    url("assets/fonts/apercu-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: "apercu-regular";
  background-color: rgb(245, 245, 245);
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #f5f5f5;
}

.header-style {
  font-family: "apercu-bold";
  color: #323232;
  position: relative;
  bottom: -10px;
  font-weight: normal;
}

.container {
  background-color: white;
  height: auto;
  width: 100%;
  border-color: #ccc;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  margin-right: 0px;
}

.cursor {
  cursor: pointer;
}

.float-left {
  float: left;
}

.load-more {
  width: 100px;
  margin: 10px auto 20px;
  display: block;
}

/**
Custom icons classes
 */
.icon-active {
  background-color: #de9a75 !important;
}

.smoking-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cstyle%3E .st0,.st3%7Bfill:none%7D.st3%7Bstroke:%23ffffff;stroke-width:5;stroke-miterlimit:10%7D %3C/style%3E%3Cpath class='st0' d='M0 0h128v128H0z' id='icon-frame'/%3E%3Cg id='icons'%3E%3Cg id='icons_2_' transform='translate(5 5.753)'%3E%3Cpath id='Pfad_1846_2_' class='st3' d='M20.298 66.447v14.79h80.454v-14.79H20.298z'/%3E%3Cpath id='Linie_574_2_' class='st3' d='M35.134 81.236V66.447'/%3E%3Cpath id='Pfad_1847_2_' class='st3' d='M23.429 58.022c0-11.927 7.3-23.607 20.377-23.607S61.27 26.63 61.27 18.965'/%3E%3Cpath id='Pfad_1848_2_' class='st3' d='M68.592 18.966c0 11.926-7.36 22.064-20.445 22.064-8.855-.737-16.631 5.843-17.369 14.697-.063.76-.072 1.524-.027 2.286'/%3E%3C/g%3E%3Cpath id='Rectangle_745_8_' class='st0' d='M70.349 93.084h32v32h-32z'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #53818a;
}

.cannabis-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cstyle%3E .st0%7Bfill:none;%7D %3C/style%3E%3Cpath class='st0' d='M0 0h128v128H0z' id='icon-frame'/%3E%3Cg id='icons'%3E%3Cpath id='Pfad_1845_2_' d='M65.487 113.553V96.395c10.318 5.1 17.381 6.852 23.115 5.734-1.661-3.401-4.409-5.833-7.081-8.327 3.003-.93 5.711-1.725 8.378-2.617 8.942-3.46 16.27-8.633 23.23-15.23 0 0-7.959-5.391-26.637-.611a79.179 79.179 0 0016.117-35.49 81.487 81.487 0 00-27.13 18.812S73.672 26.165 63.98 14.753c-9.692 11.412-11.499 43.913-11.499 43.913a81.487 81.487 0 00-27.13-18.812 79.179 79.179 0 0016.117 35.49c-18.678-4.78-26.636.612-26.636.612 6.959 6.596 14.287 11.77 23.23 15.23 2.666.891 5.375 1.687 8.377 2.616-2.672 2.494-5.42 4.926-7.081 8.327 5.734 1.118 12.797-.634 23.116-5.734v17.158h3.012z' fill='none' stroke='%23ffffff' stroke-width='5' stroke-miterlimit='10'/%3E%3Cpath id='Rectangle_745_9_' class='st0' d='M70.349 93.084h32v32h-32z'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #53818a;
}

.vaping-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cstyle%3E .st0,.st3%7Bfill:none%7D.st3%7Bstroke:%23ffffff;stroke-width:5;stroke-miterlimit:10%7D %3C/style%3E%3Cpath class='st0' d='M0 0h128v128H0z' id='icon-frame'/%3E%3Cg id='icons'%3E%3Cg id='Gruppe_958_3_' transform='translate(429.2 64.991)'%3E%3Cpath id='Linie_482_3_' class='st3' d='M-359.636-13.883V-.981'/%3E%3C/g%3E%3Cg id='Gruppe_958_2_' transform='translate(429.2 64.991)'%3E%3Cpath id='Pfad_1715_2_' class='st3' d='M-365.2-50.48c-3.356 0-6.125 3.134-6.316 6.341-.309 3.382-.52 6.564-.562 9.98-.03 2-.611 2.718-2.748 2.735-2.36-.017-3.66 1.823-3.66 3.485 0 2.319-.136 4.6-.238 6.895-.05 1.086.086 2.341 1.2 2.515 1.945.313 3.17 1.74 3.18 3.862.043 1.313-.782 1.93-1.598 2.267-4.343 1.73-6.341 7.212-6.341 12.492.042 13.701 0 27.402 0 41.103 0 5.25 2.029 7.288 7.186 7.3 3.386 0 6.494-.008 9.897-.008s6.51.009 9.897.009c5.157-.013 7.186-2.05 7.186-7.301 0-13.7-.042-27.402 0-41.103 0-5.28-1.998-10.762-6.34-12.492-.817-.337-1.642-.954-1.599-2.267.01-2.123 1.235-3.549 3.18-3.862 1.114-.174 1.25-1.429 1.2-2.515-.102-2.296-.237-4.576-.237-6.895 0-1.662-1.3-3.502-3.661-3.485-2.137-.017-2.718-.736-2.748-2.735-.042-3.416-.253-6.598-.562-9.98-.19-3.207-2.96-6.34-6.316-6.34'/%3E%3Cpath id='Linie_482_2_' class='st3' d='M-370.764-13.883V-.981'/%3E%3C/g%3E%3Cpath class='st3' d='M46.917 102.597h34.166M46.921 64.01h34.162M54.759 51.108H73.24M51.677 46.462h24.646M55.458 33.45h17.084'/%3E%3Cpath id='Rectangle_745_10_' class='st0' d='M70.349 93.084h32v32h-32z'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #53818a;
}
/**
End of custom icons
 */

/**Style overrides
 */
.rs-container rs-container-has-sidebar {
  height: 100vh;
}

.rs-nav-item > .rs-nav-item-content {
  color: #747474;
}

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  min-width: 200px;
}

ul > li:active {
  font-size: 18px;
  font-weight: bold;
  margin-left: -30px;
  color: Black;
}

.rs-nav-item-active:hover {
  background-color: #e5e5ea;
}

.menu-item-class:active {
  margin-left: 0px;
  text-decoration: none;
  font-family: "apercu-bold";
  font-weight: 100;
}

.rs-sidenav .rs-nav-item .rs-nav-item-content,
.rs-sidenav .rs-dropdown .rs-dropdown-toggle {
  padding-left: 20px;
}

.rs-nav .rs-nav-item-active > .rs-nav-item-content {
  color: #323232;
  border-left: 5px solid #323232;
  padding-left: 15px;
}

.rs-nav .rs-nav-item-active > .rs-nav-item-content:hover {
  color: #747474;
}

.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
.rs-nav .rs-nav-item-active > .rs-nav-item-content,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:hover,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:focus,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
  color: #323232;
}

.rs-tooltip-inner {
  text-align: left !important;
}

.rs-footer a:hover {
  text-decoration: underline !important;
}

a,
a:hover {
  color: black;
}

.rs-badge-wrapper .rs-badge-content {
  right: -20px;
  background: #a55452;
  padding: 5px;
  padding-bottom: 0px;
  padding-top: 2px;
}

.rs-btn-default {
  background-color: #f5f5f5;
  color: #323232;
}
/**
End of overrides
 */

/**Map Styles
 */
.smallContainer {
  width: 700px;
  height: 600px;
}

.smallContainer .highcharts-container {
  top: -30px !important;
}

.highcharts-tooltip {
  z-index: 9999 !important;
}
/**End map styles
 */

/**App Zoom
 */
@media only screen and (min-width: 1405px) {
  .rs-sidebar {
    background: white;
  }
  .scroll-top {
    right: calc(50% - 800px);
  }
}

@media only screen and (max-width: 1420px) {
  .rs-sidebar {
    position: fixed;
    z-index: 10;
    background: white;
  }

  .scroll-top {
    right: 40px;
  }

  .side-nav {
    box-shadow: none;
  }

  .dashboard .hamburger {
    display: block;
    z-index: 11;
  }

  .wrapper {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1070px) {
  .ObjectFilters .rs-picker-select {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  #main-container {
    padding: 0px;
    border-radius: 0px;
  }
  .side-nav {
    height: 100% !important;
    box-shadow: none;
  }
  .container {
    padding: 0px;
  }
  .header-style {
    margin-left: 10px;
  }

  .rs-picker-toggle-wrapper {
    width: 80px !important;
  }

  .rs-hidden-sm {
    display: none !important;
  }

  .Login .form,
  .Signup .form {
    width: 100%;
  }

  .Login form,
  .Signup form {
    width: 100%;
    position: initial;
  }

  .Login .title,
  .Signup .title {
    float: none;
    padding: 0px;
    margin: auto;
    margin-bottom: 20px;
    width: 320px;
    font-size: 25px;
  }

  .Login .form,
  .Signup .form {
    top: -30px;
  }

  .FeedPost .sentiment,
  .FeedPost .timeStamp {
    display: none;
  }

  .FeedPost .issues,
  .FeedPost .mentions {
    margin-left: 0px !important;
  }

  .profile-info {
    display: none;
  }

  .user-menu button {
    width: 90px;
    text-overflow: ellipsis;
  }
}

/**End app zoom
 */

.highcharts-root {
  width: 100%;
}

.highcharts-container {
  width: 100% !important;
}

.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.button-link:hover,
.button-link:focus {
  text-decoration: none;
}

.rs-divider-horizontal {
  margin: 7px 0px;
  background-color: #bfbfbf;
}

.rs-picker-toggle-placeholder {
  font-family: "apercu-mono-regular";
  font-size: 13px;
  color: #323232;
}

.rs-picker-has-value {
  background-color: #f5f5f5;
  color: #323232;
  font-size: 13px;
}

.filters-style {
  position: relative;
  bottom: -7px;
  margin-top: 8px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #323232;
}

.rs-picker-subtle .rs-btn.active,
.rs-picker-subtle .rs-picker-toggle.active {
  background-color: #f5f5f5;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #323232;
}

.rs-tooltip .rs-tooltip-inner {
  padding: 8px 8px 4px 8px;
  font-family: "apercu-bold";
  font-size: 13px;
  color: #fff;
  background-color: #323232;
}

.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-btn.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover {
  background-color: #f5f5f5;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #323232;
}

.rs-notification-title {
  font-size: 18px;
  color: #323232;
  font-family: "apercu-bold";
}

.mt-15px {
  margin-top: 15px;
}

.rs-notification-description {
  font-size: 18px;
  color: #323232;
  font-family: "apercu-regular";
}

.border-style {
  border-top: solid 2px #f5f5f5;
  border-left: solid 2px #f5f5f5;
  border-right: solid 2px #f5f5f5;
  color: #323232;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.filter-font {
  font-family: "apercu-mono-regular";
  font-size: 13px;
}

.rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
  margin-left: 0px;
}

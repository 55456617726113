.Login {
  padding: 100px 15px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.hr-style {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-signout {
  color: #bfbfbf;
  font-size: 16px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  opacity: 1;
  font-family: "apercu-mono-regular";
}

.div-forgot-password {
  padding-top: 10px;
}

.txt-err {
  min-height: 20px;
}

.btn-font-color {
  color: black;
  background-color: rgb(238, 238, 238);
}

.div-signup-wrapper {
  margin: 0px auto;
  width: 60%;
}

.forgot-password-style {
  text-decoration: none;
  letter-spacing: 0px;
  color: #bfbfbf;
  opacity: 1;
}

.forgot-password-style:visited {
  text-decoration: none;
  letter-spacing: 0px;
  color: #bfbfbf;
  opacity: 1;
}

.forgot-password-style:hover {
  text-decoration: none;
  letter-spacing: 0px;
  color: #bfbfbf;
  opacity: 1;
}

.Login .form {
  margin: 0 auto;
  width: 700px;
  top: 85px;
  position: relative;
}
.input-txt-style {
  border: 1px solid #bfbfbf;
  color: #323232;
  font-family: "apercu-mono-regular";
}

.Login form {
  width: 400px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: absolute;
  right: 15px;
  max-width: 1388px;
  min-width: 357px;
}

.confirm-style {
  margin-left: 10px;
}

.form-group {
  margin: 0;
}

.Login .title {
  float: left;
  padding: 48px 30px 30px 30px;
  font-size: 32px;
  height: 100px;
  color: #b14e4e;
  width: 50px;
  font-weight: 900;
  line-height: 1em;
  font-family: "apercu-bold";
}

.Login .form-control {
  font-size: 16px;
}

.Login .Loginbtn {
  background-color: #f5f5f5;
  border: #f5f5f5;
  font-size: 16px;
}

.Login .LoaderButton .btn-default {
  border: none;
}

.ObjectFilters .filter-font {
  font-family: "apercu-mono-regular";
  font-size: 13px;
}

.ObjectFilters .border-style {
  border-top: solid 2px #f5f5f5;
  border-left: solid 2px #f5f5f5;
  border-right: solid 2px #f5f5f5;
  color: "#323232";
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ObjectFilters .rs-btn.rs-btn-icon {
  padding-left: 5px;
  padding-right: 5px;
}

.ObjectFilters .rs-btn {
  margin-bottom: -1px;
}

.ObjectFilters .mr8px {
  margin-right: 8px;
}

.ObjectFilters .ml16px {
  margin-left: 16px;
}

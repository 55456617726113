.Top3Issues {
  color: #505050;
  font-family: "apercu-bold";
  padding: 16px;
  font-size: 16px;
}

.Top3Issues .row-space {
  font-family: "apercu-regular";

  padding-top: 24px;
}

.Top3Issues .title {
  font-size: 16px;
  color: #323232;
}

.Top3Issues .rs-panel-heading,
.Top3Issues .rs-panel-body {
  padding: 0px;
}

.buttonsToolbar {
  color: #323232;
  font-family: "apercu-mono-regular";
}

.NewsFeedFilters .rs-btn.rs-btn-icon {
  padding-left: 10px;
}

.NewsFeedFilters .mr8px {
  margin-left: 8px;
}

.NewsFeedFilters .mt5px {
  margin-top: 5px;
}

.NewsFeedFilters .border-style {
  border-top: solid 2px #f5f5f5;
  border-left: solid 2px #f5f5f5;
  border-right: solid 2px #f5f5f5;
  color: #323232;
  border-radius: 5px 5px 0px 0px;
}

.NewsFeedFilters .filter-font {
  font-family: "apercu-mono-regular";
  font-size: 13px;
}
